














































import { Getter } from  'vuex-class';
import {Prop, Vue,Component} from 'vue-property-decorator';
// Utilities
import kebabCase from "lodash/kebabCase";
//@ts-ignore
import Themeable from "vuetify/lib/mixins/themeable";
import { ThemeConfig } from '@/temas';
import { Children } from '@/store/Auth/types';
@Component({
    mixins:[Themeable],
    name:'ItemGroup',
    components:{
        'base-item' : () => import('@/components/Base/Item.vue'),
        'base-item-group' : () => import('@/components/Base/ItemGroup.vue')
    }
})
export default class ItemGroup extends Vue
{
  //isDark:boolean = false;
  inheritAttrs:boolean= false;
  listModel:number= 0
  @Prop({type:Object})
  item!:Children

  @Prop({type:Boolean,default:false})
  subGroup!:boolean

  @Prop({type:Boolean,default:false})
  text!:boolean

  @Getter('getThemeMode',{namespace:'sistemaModule'})getThemeMode!:ThemeConfig

  get children() {
      return this.item.children.map(item => ({
        ...item,
        //to: !item.to ? undefined : `/app/${this.item.group}/${item.to}`
        to: !item.to ? undefined : item.to
      }));
    }
   get  computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach(val => {
        text += val.substring(0, 1);
      });

      return text;
    }
    get group() {
      return this.genGroup(this.item.children);
    }
    //Metodos
    genGroup(children:Array<any>)
    {
      return children
        .filter(item => item.to)
        .map(item => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
            console.log("child");
          }
          return group;

        })
        .join("|");
    }
};
